import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import particlesVertexShader from './shaders/particles/vertex.glsl'
import particlesFragmentShader from './shaders/particles/fragment.glsl'
import particlesFragmentShaderCircle from './shaders/particles/fragmentCircle.glsl'
import { Vector3 } from 'three'
import gsap from 'gsap'
/**
 * Loaders
 */
 const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        myGreeting();
        console.log('loaded')
    },

    // Progress
    () =>
    {
        console.log('progress')
    }
)

const gltfLoader = new GLTFLoader(loadingManager)
const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)




let particlematerial = null


/**
 * Base
 */
// Debug

const gui = new dat.GUI()
const debugObject = {}
gui.hide()
const params = {
    exposure: 1,
    bloomStrength: 1.9,
    bloomThreshold: 0,
    bloomRadius: 1,
    materialColor: '#ffeded'
};

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
console.log(scene)
/**
 * Update all materials
 */
const updateAllMaterials = () =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            // child.material.envMap = environmentMap
            child.material.envMapIntensity = debugObject.envMapIntensity
            child.castShadow = true
            child.receiveShadow = true
            
        }
    })
}

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
    '/textures/environmentMaps/0/px.jpg',
    '/textures/environmentMaps/0/nx.jpg',
    '/textures/environmentMaps/0/py.jpg',
    '/textures/environmentMaps/0/ny.jpg',
    '/textures/environmentMaps/0/pz.jpg',
    '/textures/environmentMaps/0/nz.jpg'
])

environmentMap.encoding = THREE.sRGBEncoding

//scene.background = environmentMap
scene.environment = environmentMap

debugObject.envMapIntensity = 2.0
gui.add(debugObject, 'envMapIntensity').min(0).max(10).step(0.001).onChange(updateAllMaterials)


/**
 * Sizes
 */
 const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}


// Texture
const textureLoader = new THREE.TextureLoader()
const gradientTexture = textureLoader.load('/textures/particles/99.png')
gradientTexture.magFilter = THREE.NearestFilter
const gradientTexture2 = textureLoader.load('/textures/particles/88.png')
gradientTexture2.magFilter = THREE.NearestFilter
//const particleTexture = textureLoader.load('/textures/particles/9.png')
/**
 * Models
 */
// gltfLoader.load(
//     '/models/FlightHelmet/glTF/FlightHelmet.gltf',
//     (gltf) =>
//     {
//         gltf.scene.scale.set(10, 10, 10)
//         gltf.scene.position.set(0, - 4, 0)
//         gltf.scene.rotation.y = Math.PI * 0.5
//         scene.add(gltf.scene)

//         gui.add(gltf.scene.rotation, 'y').min(- Math.PI).max(Math.PI).step(0.001).name('rotation')

//         updateAllMaterials()
//     }
// )

gltfLoader.load(
    '/models/signnew5.glb',
    (gltf) =>
    {

        if(sizes.width < 991) {
            gltf.scene.scale.set(0.6, 0.6, 0.6)
            gltf.scene.position.set(0, -7, 0)
        } else {
            gltf.scene.position.set(0, -7, 0.5)
        }
        //gltf.scene.scale.set(1.1, 1.1, 1.1)
        
       

        
        // gltf.scene.rotation.y = Math.PI * 0.5
        gltf.scene.rotation.x = - Math.PI * 0.5
        scene.add(gltf.scene)
        //console.log(gltf.scene.children[4].geometry)
        //gltf.scene.children[4].geometry.computeVertexNormals()
        updateAllMaterials()
    }
)




const objectsDistance = 4

/**
 * Particles
 */
// Geometry

const generateStars = () =>
{
const particlesCount = 2000
const positions = new Float32Array(particlesCount * 3)
const positionsCircle = new Float32Array(particlesCount * 3)
const colors = new Float32Array(particlesCount * 3)
const scales = new Float32Array(particlesCount * 1)
const opacitys = new Float32Array(particlesCount * 1)

for(let i = 0; i < particlesCount; i++)
{
    positions[i * 3 + 0] = (Math.random() - 0.5) * 70
    positions[i * 3 + 1] = objectsDistance * 0.5 - Math.random() * objectsDistance 
    positions[i * 3 + 2] = (Math.random() - 0.5) * 70

    scales[i] = Math.random()

    opacitys[i] = Math.random()

}

for(let i = 0; i < particlesCount; i++)
{

    positionsCircle[i * 3 + 0] = (Math.random() - 0.5) * 70
    positionsCircle[i * 3 + 1] = objectsDistance * 0.5 - Math.random() * objectsDistance 
    positionsCircle[i * 3 + 2] = (Math.random() - 0.5) * 70

}



const particlesGeometry = new THREE.BufferGeometry()

particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
// particlesGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
particlesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1))
particlesGeometry.setAttribute('aOpac', new THREE.BufferAttribute(opacitys, 1))



const particlesGeometryCircle = new THREE.BufferGeometry()
particlesGeometryCircle.setAttribute('position', new THREE.BufferAttribute(positionsCircle, 3))
particlesGeometryCircle.setAttribute('aScale', new THREE.BufferAttribute(scales, 1))
particlesGeometryCircle.setAttribute('aOpac', new THREE.BufferAttribute(opacitys, 1))

// Material
// const particlesMaterial = new THREE.PointsMaterial({
//     color: params.materialColor,
//     sizeAttenuation: true,
//     size: 0.5,
//     map: particleTexture,
//     transparent: true, 
//     alphaMap: particleTexture,
//     depthWrite: true,
//     blending: THREE.AdditiveBlending,
//     vertexColors: true, 
// })

//Shader Material for Particles
 particlematerial = new THREE.ShaderMaterial( {
    transparent: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    //vertexColors: true,
    uniforms: {
        uSize: { value: 500 * renderer.getPixelRatio() },
        color: { value: new THREE.Color( 0xffffff ) },
        uTexture: { value: gradientTexture },

        //Could potentially add slow movement very slow
        uTime: { value: 0 },
    },
    vertexShader: particlesVertexShader,
    fragmentShader: particlesFragmentShader

} );

const particlematerialCircle = new THREE.ShaderMaterial( {
    transparent: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    //vertexColors: true,
    uniforms: {
        uSize: { value: 500 * renderer.getPixelRatio() },
        color: { value: new THREE.Color( 0xffffff ) },
        uTexture: { value: gradientTexture2 },

    },
    vertexShader: particlesVertexShader,
    fragmentShader: particlesFragmentShader

} );



// Points
const particles = new THREE.Points(particlesGeometry, particlematerial)
const particlesCircle = new THREE.Points(particlesGeometryCircle, particlematerialCircle)
scene.add(particles)
scene.add(particlesCircle)
particles.position.y = -15.0
particlesCircle.position.y = -15.0

}

/**
 * Lights
 */
// const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
// directionalLight.castShadow = true
// directionalLight.shadow.camera.far = 15
// directionalLight.shadow.mapSize.set(1024, 1024)
// directionalLight.shadow.normalBias = 0.05
// directionalLight.position.set(0.25, 3, - 2.25)
// scene.add(directionalLight)

// gui.add(directionalLight, 'intensity').min(0).max(10).step(0.001).name('lightIntensity')
// gui.add(directionalLight.position, 'x').min(- 5).max(5).step(0.001).name('lightX')
// gui.add(directionalLight.position, 'y').min(- 5).max(5).step(0.001).name('lightY')
// gui.add(directionalLight.position, 'z').min(- 5).max(5).step(0.001).name('lightZ')



window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    //effectComposer.render()
    effectComposer.setSize(sizes.width, sizes.height)
    effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    
})

/**
 * Camera
 */
// Group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 3, 0)
cameraGroup.add(camera)



// const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
// camera.position.set(0, 3, 0)
// scene.add(camera)

var point = new THREE.Vector3( 0, 0, 0 );

camera.lookAt( point );

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true



/**
 * Scroll
 */
 let scrollY = window.scrollY


 window.addEventListener('scroll', () =>
 {
     scrollY = window.scrollY
 
     //console.log(scrollY)
 })


/**
 * Cursor
 */
 const cursor = {}
 cursor.x = 0
 cursor.y = 0

window.addEventListener('mousemove', (event) =>
{
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5

    //console.log(cursor)
})

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    //antialias: true, 
     alpha: true
})
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ReinhardToneMapping
renderer.toneMappingExposure = 3
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))



/**
 * Post processing
 */
 const effectComposer = new EffectComposer(renderer)
 effectComposer.setSize(sizes.width, sizes.height)
 effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass)


const fxaaPass = new ShaderPass( FXAAShader );
const pixelRatio = renderer.getPixelRatio();
const uniforms = fxaaPass.material.uniforms;
uniforms[ 'resolution' ].value.x = 1 / ( window.innerWidth * pixelRatio );
uniforms[ 'resolution' ].value.y = 1 / ( window.innerHeight * pixelRatio );
effectComposer.addPass( fxaaPass );

const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
bloomPass.threshold = params.bloomThreshold;
bloomPass.strength = params.bloomStrength;
bloomPass.radius = params.bloomRadius;
effectComposer.addPass( bloomPass );


gui.add( params, 'exposure', 0.1, 2 ).onChange( function ( value ) {

    renderer.toneMappingExposure = Math.pow( value, 4.0 );

} );

gui.add( params, 'bloomThreshold', 0.0, 1.0 ).onChange( function ( value ) {

    bloomPass.threshold = Number( value );

} );

gui.add( params, 'bloomStrength', 0.0, 3.0 ).onChange( function ( value ) {

    bloomPass.strength = Number( value );

} );

gui.add( params, 'bloomRadius', 0.0, 1.0 ).step( 0.01 ).onChange( function ( value ) {

    bloomPass.radius = Number( value );

} );


// gui
//     .add(renderer, 'toneMapping', {
//         No: THREE.NoToneMapping,
//         Linear: THREE.LinearToneMapping,
//         Reinhard: THREE.ReinhardToneMapping,
//         Cineon: THREE.CineonToneMapping,
//         ACESFilmic: THREE.ACESFilmicToneMapping
//     })
// gui.add(renderer, 'toneMappingExposure').min(0).max(10).step(0.001)

/**
 * Generate the Stars
 */
 generateStars()


 const starmaterial = new THREE.LineBasicMaterial( { color: 0xffffff } );

const points = [];
points.push( new THREE.Vector3( -0.2, 0, 0 ) );
points.push( new THREE.Vector3( 0, 0, 0.2 ) );
const stargeometryLeftDiag = new THREE.BufferGeometry().setFromPoints( points );
const shootingStarLeftDiag = new THREE.Line( stargeometryLeftDiag, starmaterial );

const pointsLR = [];
pointsLR.push( new THREE.Vector3( -0.3, 0, 0 ) );
pointsLR.push( new THREE.Vector3(  0, 0, 0.2  ) );

const stargeometryLR = new THREE.BufferGeometry().setFromPoints( pointsLR );
const shootingStarLR = new THREE.Line( stargeometryLR, starmaterial );


scene.add(shootingStarLR)
scene.add(shootingStarLeftDiag)
shootingStarLeftDiag.position.x = -100
shootingStarLR.position.x = -100

function animateShootingStar() {

    let randomNumSelector = Math.floor(Math.random() * 2) + 1
    let randomNum = Math.floor(Math.random() * 6) + 3

    switch(randomNumSelector) {
        case 1:
            gsap.fromTo(
                shootingStarLeftDiag.position, 
                {
                    x: -randomNum,
                    //y: -4,
                    z: -randomNum,
                },
                {   
                    delay: "random(2, 4)",
                    //yoyo: true,
                    //repeat: 3,
                    duration: "random(2, 3)",
                    //duration: 3,
                    repeatRefresh: true,
                    onComplete: animateShootingStar,
                    // ease: "expo.Out",
                    x: randomNum,
                    //y: 2,
                    z: randomNum,
                    
                },
        
            )
          break;
        case 2:
            gsap.fromTo(
                shootingStarLR.position, 
                {
                    x: -4,
                    //y: -4,
                    z: -4,
                },
                {   
                    delay: "random(2, 3)",
                    //yoyo: true,
                    //repeat: 3,
                    duration: "random(2, 4)",
                    //duration: 3,
                    repeatRefresh: true,
                    onComplete: animateShootingStar,
                    // ease: "expo.Out",
                    x: 4,
                    //y: 2,
                    z: 1,
                    
                },
        
            )
          break;
        case 3:
          // code block
        break;
        default:
          // code block
      }

    

    
}
animateShootingStar()
 
 function myGreeting() {
    const letterA1 =  scene.getObjectByName('Z1')
    const the =  scene.getObjectByName('The')
    const big =  scene.getObjectByName('big')
    const outsideTube =  scene.getObjectByName('Outsidetube')
    const insideTube = scene.getObjectByName('middletube')

    var oldColor = letterA1.material.clone()
    oldColor = oldColor.color
    var newColor = letterA1.material.clone()
    newColor.color.r = 0.0
    newColor.color.g = 0.0
    newColor.color.b = 0.0
    //letterA1.material = newColor


    var oldColorThe = the.material.clone()
    oldColorThe = oldColorThe.color
    var newColorThe = the.material.clone()
    newColorThe.color.r = 0.0
    newColorThe.color.g = 0.0
    newColorThe.color.b = 0.0
    //the.material = newColorThe
    

    function animateZ() {
        gsap.fromTo(
            letterA1.material.color, 
            {
                r: oldColor.r,
                g: oldColor.g,
                b: oldColor.b,
            },
            {   
               
                yoyo: true,
                repeat: 3,
                duration: "random(0.1, 0.5)",
                repeatRefresh: true,
                //onComplete: animateZ,
                // ease: "expo.Out",
               
                r: newColor.r,
                g: newColor.g,
                b: newColor.b,
                
            },
    
        )
    }

    animateZ()

    


    function animateSign() {

        gsap.fromTo(
            the.material.color, 
            {
                r: oldColorThe.r,
                g: oldColorThe.g,
                b: oldColorThe.b,
            },
            {   
               
                yoyo: true,
                repeat: 3,
                duration: "0.25",
                repeatRefresh: true,
                r: newColorThe.r,
                g: newColorThe.g,
                b: newColorThe.b,
                onComplete: glow,
                
            },
    
        )

    }
    animateSign()



    function glow() {

        setTimeout(function() {

            gsap.fromTo(
                the.material.color, 
                {
                    r: oldColorThe.r,
                    g: oldColorThe.g,
                    b: oldColorThe.b,
                },
                {   
                   
                    yoyo: true,
                    repeat: -1,
                    duration: 0.5,
                    ease: "circ.inOut",
                    repeatRefresh: true,
                    r: oldColorThe.r - 0.02,
                    g: oldColorThe.g - 0.02,
                    b: oldColorThe.b - 0.02,
                    
                },
        
            )
    
                
                    //console.log(letterA1.material.color)
            gsap.fromTo(
                letterA1.material.color, 
                {
                    r: oldColor.r,
                    g: oldColor.g,
                    b: oldColor.b,
                },
                {   
                   
                    yoyo: true,
                    repeat: -1,
                    duration: 0.5,
                    repeatRefresh: true,
                    //onComplete: animateZ,
                    ease: "circ.inOut",
                    r: oldColor.r,
                    g: oldColor.g - 0.02,
                    b: oldColor.b - 0.02,
                    
                },
        
            )
    


        }, 1500)


    }



  }












//  const axesHelper = new THREE.AxesHelper( 5 );
//  scene.add( axesHelper );

/**
 * Animate
 */
 const clock = new THREE.Clock()
 let previousTime = 0

const tick = () =>
{

    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
    //let twinkle = Math.random()
    //console.log(twinkle)
    // Update controls
   // controls.update()
    
    // const letterZ =  scene.getObjectByName('A1')
    //  if(letterZ) {
    //     console.log(letterZ.material)
    //     var oldColor = letterZ.material.clone()
    //     oldColor = oldColor.color
        
    //     var newColor = letterZ.material.clone()
    //     newColor.color.r = 0.0
    //     newColor.color.g = 0.0
    //     newColor.color.b = 0.0
    //     letterZ.material = newColor
    //     // letterZ.material.color.r = 1
    //     // letterZ.material.color.g = 1
    //     // letterZ.material.color.b = 1
    //     var targetColor = new THREE.Color(0xafe2f3);
    //     console.log(letterZ.material.color)
    //     gsap.to(
    //         letterZ.material.color, 
    //         {   
    //             duration: 2,
    //             r: 1.0,
    //             g: 1.0,
    //             b: 1.0,
    //         },

    //     )

    //console.log(letterZ.children[1])
    //const initalColor = letterZ.children[4].material.color;
    //letterZ.children[4].material.color = new Vector3(0.0,0.0,0.0)
        //letterZ.children[1].material.color.b = 0.0
        //letterZ.children[1].material.color.r = 0.0
     
     //}
    
    // Render
    //renderer.render(scene, camera)
    effectComposer.render()

    //particlematerial.uniforms.uTime.value = twinkle

    //gradientmaterial.uniforms.uMouse.value = cursor

    // Animate camera
    camera.position.z = scrollY / sizes.height * 8

   // const parallaxX = cursor.x * 0.05
   // const parallaxY = - cursor.y * 0.05

    //shootingStar.position.x += 0.5

   // cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime
   // cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()